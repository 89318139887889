@import './variables.scss';

// Global styles
body {
  font-family: 'Roboto', sans-serif;
  color: #333;
}

// Header styles
.header-scrolled {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: $shadow-sm;
}

// Navigation styles
.nav-link {
  @include transition-base;
  position: relative;
  text-decoration: none;
  
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $primary;
    @include transition-base;
    opacity: 0;
    border: none;
    outline: none;
    box-shadow: none;
  }
  
  &:not(.btn):hover::after,
  &:not(.btn).active::after {
    width: 100%;
    opacity: 1;
  }
}

// Card styles
.card {
  @include transition-base;
  
  &.hover-card:hover {
    transform: translateY(-5px);
    box-shadow: $shadow-lg;
  }
}

// Icon styles
.icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 50%;
  @include gradient-light;
  @include transition-base;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    color: $primary;
    @include transition-base;
    width: 100%;
    height: 100%;
  }
  
  &:hover {
    @include gradient-primary;
    
    svg {
      color: white;
      transform: scale(1.1);
    }
  }
}

// Section styles
.section-title {
  margin-bottom: 3rem;
  
  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    position: relative;
    padding-bottom: 1rem;
    
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 50px;
      height: 3px;
      background: $primary;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  p {
    margin: 0;
    font-size: 1.1rem;
    color: #666;
  }
}

// Button styles
.btn {
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  border-radius: 30px;
  @include transition-smooth;
  text-decoration: none !important;
  
  &.btn-primary {
    color: white;
    
    &:hover {
      color: white;
      transform: translateY(-2px);
      box-shadow: 0 5px 15px rgba($primary, 0.3);
    }
  }
}

// Animation utilities
.fade-up {
  animation: fadeIn 0.5s ease-out forwards;
}

.slide-in-left {
  animation: slideIn 0.5s ease-out forwards;
}

.card img {
  display: block;
  margin: 0 auto;
} 

// Clients Carousel styles
.clients-carousel-section {
  padding: 60px 0;
  
  .client-card {
    transition: all 0.3s ease;
    border-radius: 10px;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: $shadow-md;
    }
    
    .client-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 150px;
      margin-bottom: 1rem;
      
      img {
        max-height: 120px;
        object-fit: contain;
        transition: all 0.3s ease;
      }
    }
    
    h5 {
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: $primary;
    }
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    width: 40px;
    height: 40px;
    background: $primary;
    border-radius: 50%;
    opacity: 0.8;
    top: 50%;
    transform: translateY(-50%);
    
    &:hover {
      opacity: 1;
    }
  }
  
  .carousel-control-prev {
    left: -20px;
  }
  
  .carousel-control-next {
    right: -20px;
  }
  
  .carousel-indicators {
    bottom: -40px;
    
    button {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $primary;
      opacity: 0.5;
      
      &.active {
        opacity: 1;
      }
    }
  }
}

// NavDropdown styles
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: inherit;
}

.dropdown-menu {
  padding: 0.5rem 0;
  margin: 0;
  border: none;
  border-radius: 0.5rem;
  box-shadow: $shadow-md;
  
  .dropdown-item {
    padding: 0.5rem 1.5rem;
    font-weight: 500;
    transition: all 0.2s;
    
    &:hover, &:focus, &.active {
      color: $primary;
      background-color: rgba($primary, 0.1);
    }
  }
}

// Aggiusta il problema dell'header che si sovrappone al contenuto
.page-hero, section:first-of-type {
  padding-top: 120px !important; /* Aumenta il padding superiore per evitare sovrapposizioni */
}

// Risolvi il problema della sottosezione che si sovrappone all'header in pagine specifiche
.page-hero h1, .page-hero .lead {
  position: relative;
  z-index: 1;
}

// Risolvi il problema della linea nera in "Su di noi"
.dropdown-toggle {
  position: relative;
  
  &::after {
    border-top-color: currentColor;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  
  &:hover::after {
    border-top-color: $primary;
  }
}

.nav-item.dropdown .nav-link::after {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

// Rimuovi il background blu dalle pagine
.bg-primary, .bg-gradient-primary {
  background: white !important;
  background-image: none !important;
}

// Cambia il colore delle icone del servizio
.service-icon {
  i, svg {
    color: $primary !important; /* Default rosso */
  }
  
  &.service-icon-1 i, &.service-icon-1 svg {
    color: #ffb74d !important; /* Arancione */
  }
  
  &.service-icon-2 i, &.service-icon-2 svg {
    color: #ffeb3b !important; /* Giallo */
  }
  
  &.service-icon-3 i, &.service-icon-3 svg {
    color: #4caf50 !important; /* Verde */
  }
} 