:root {
  --primary-color: #1E417D;
  --secondary-color: #3498db;
  --accent-color: #f39c12;
  --text-color: #333333;
  --light-bg: #f8f9fa;
  --dark-bg: #343a40;
  --font-primary: 'Open Sans', sans-serif;
  --font-heading: 'Raleway', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-primary);
  color: var(--text-color);
  background-color: #fff;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  font-weight: 700;
  color: var(--primary-color);
}

a {
  text-decoration: none;
  color: var(--primary-color);
  transition: all 0.3s ease;
}

a:hover {
  color: var(--secondary-color);
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.section-padding {
  padding: 80px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 50px;
}

.section-title h2 {
  font-size: 32px;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--primary-color);
  bottom: 0;
  left: calc(50% - 25px);
}

/* Animazioni */
.animate__animated {
  animation-duration: 1s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

/* Media Queries */
@media (max-width: 991px) {
  .section-padding {
    padding: 60px 0;
  }
}

@media (max-width: 768px) {
  .section-padding {
    padding: 40px 0;
  }
  
  .section-title h2 {
    font-size: 28px;
  }
}

@media (max-width: 576px) {
  .section-title h2 {
    font-size: 24px;
  }
} 