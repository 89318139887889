// Variables
$primary: #E31E24;
$primary-dark: darken($primary, 10%);
$primary-light: lighten($primary, 10%);

// Mixins
@mixin gradient-primary {
  background: linear-gradient(135deg, $primary 0%, $primary-dark 100%);
}

@mixin gradient-light {
  background: linear-gradient(135deg, rgba($primary, 0.1) 0%, rgba($primary, 0.2) 100%);
}

@mixin transition-base {
  transition: all 0.3s ease-in-out;
}

@mixin transition-smooth {
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

// Shadows
$shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
$shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
$shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);

// Classes
.btn-primary {
  @include gradient-primary;
  border: none;
  @include transition-smooth;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: $shadow-md;
  }
  
  &:active {
    transform: translateY(0);
  }
}

.hover-card {
  @include transition-base;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: $shadow-lg;
  }
}

.bg-gradient-primary {
  @include gradient-primary;
}

// Animations
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

// Animation classes
.fade-in {
  animation: fadeIn 0.5s ease-out;
}

.slide-in {
  animation: slideIn 0.5s ease-out;
} 